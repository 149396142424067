<template>
  <fragment>
    <v-select
      v-model="edad.edad"
      :items="edades"
      :label="$t('lbl.age')"
      outlined
      dense
    ></v-select>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    edad: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    iHab: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    iEdad: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    edades: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
    }),
  },
  methods: {
    ...mapMutations(['setShowRoomHotelCotizador']),
  },
}
</script>
