<template>
  <v-row
    v-if="!isLoading && hotel"
    no-gutters
    dense
  >
    <v-col
      cols="12"
      md="3"
    >
      <v-img
        v-if="!isLoadingPagination"
        lazy-src="@/assets/images/placeholder/hotel_placeholder.jpg"
        :src="resource === 's3' ? rutaS3 + image : rutaPublic + image"
        contain
        height="100%"
        max-width="300px"
        width="280px"
        style="text-align: right !important;border-radius: 1rem 0 0 1rem;"
      >
        <template>
          <v-chip
            class="mr-2 mt-2"
            color="primary"
          >
            <span v-if="hotel.tipo_reserva === 'free-sale'">Free Sale</span>
            <span v-else>On Request</span>
          </v-chip>

          <v-chip
            v-if="hotel.stop_sale"
            class="mr-2 mt-2"
            color="error"
          >
            {{ $t('lbl.stopSale') }}
          </v-chip>
        </template>
      </v-img>
      <v-img
        v-else
        lazy-src="@/assets/images/placeholder/hotel_placeholder.jpg"
        src="@/assets/images/placeholder/hotel_placeholder.jpg"
        contain
        height="100%"
        max-width="300px"
        width="280px"
        style="text-align: right !important;border-radius: 1rem 0 0 1rem;"
      >
      </v-img>
    </v-col>
    <v-col
      cols="12"
      md="9"
      class="ml-0 pl-0"
      style="border-top:0.25px solid #e2e2e2; border-bottom:1px solid #e2e2e2;border-right:1px solid #e2e2e2;border-radius: 0 1rem 1rem 0;"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
          class="ml-10"
        >
          <span color="dark">
            HOTEL en
            <span class="text-caption">{{ `${hotel.hotel.city}, ${hotel.hotel.country}` }}</span><br />
            <!--<span class="text-caption">{{ `${hotel.hotel.tipo_destino.name}` }}</span><br />-->
          </span>
          <span
            :style="`color: ${$vuetify.theme.themes.light.primary};`"
          >
            <strong> {{ hotel.hotel.name }} </strong><br />
          </span>
          <v-row no-gutters>
            <v-col
              cols="12"
              :md="hotel.hotel.categoria.plus ? '4' : '12'"
            >
              <v-rating
                background-color="default"
                color="primary"
                :empty-icon="icons.mdiStarOutline"
                :full-icon="icons.mdiStar"
                :half-icon="icons.mdiStarHalfFull"
                length="5"
                readonly
                size="20"
                dense
                half-increments
                :value="hotel.hotel.categoria.cant_estrellas"
              ></v-rating>
            </v-col>
            <v-col
              v-if="hotel.hotel.categoria.plus"
              cols="12"
              md="2"
            >
              PLUS
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="mt-0 mb-2"
            >
              <b>
                <v-icon
                  color="#000"
                  small
                >
                  mdi-weather-night
                </v-icon>
                {{ hotel.days_search }} {{ $t('lbl.nights') }}
                {{ $moment(cotizadorHotels.dateIn).format('D-MMM') }} -
                {{ $moment(cotizadorHotels.dateOut).format('D-MMM') }}
                <template v-if="hotel.rooms.length > 0">
                  {{ $t('lbl.in') }}
                  {{ hotel.rooms[0].days[0].plan }}
                </template>
              </b>
            </v-col>
          </v-row>
          <span>
            <b>{{ $t('lbl.cadena') }}</b>:
            {{ hotel.hotel.operador ? hotel.hotel.operador.name : '' }}
          </span><br />
          <span>
            <b>{{ $t('lbl.proveedor') }}</b>:
            {{ hotel.proveedor ? `${hotel.proveedor.name}` : '' }}
            <span v-if="hotel.sources.length > 0">
              ({{ hotel.sources[0] | capitalize }})
            </span>
          </span><br />
          <!--<v-tooltip
              v-for="(serv, indServ) in hotel.hotel.servicios_id"
              :key="indServ"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  class="mr-1"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{
                    services.filter(e => e.id === serv).length > 0 ? services.filter(e => e.id === serv)[0].icon : ''
                  }}
                </v-icon>
              </template>
              <span>{{
                services.filter(e => e.id === serv).length > 0 ? services.filter(e => e.id === serv)[0].name : ''
              }}</span>
            </v-tooltip>-->
          <v-row
            v-if="hotel.hotel.cant_departament"
            no-gutters
          >
            <v-col
              v-if="hotel.hotel.cant_departament.cant_rooms"
              cols="12"
              md="2 "
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-bed-outline
                  </v-icon>
                  <span>
                    {{ hotel.hotel.cant_departament.cant_rooms }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantRoom') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="hotel.hotel.cant_departament.cant_restaurant"
              cols="12"
              md="2"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-silverware-fork-knife
                  </v-icon>
                  <span>
                    {{ hotel.hotel.cant_departament.cant_restaurant }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantRest') }}</span>
              </v-tooltip>
            </v-col>

            <v-col
              v-if="hotel.hotel.cant_departament.cant_bars"
              cols="12"
              md="2"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-glass-cocktail
                  </v-icon>
                  <span>
                    {{ hotel.hotel.cant_departament.cant_bars }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantBars') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="hotel.hotel.cant_departament.cant_pool"
              cols="12"
              md="2"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-pool
                  </v-icon>
                  <span>
                    {{ hotel.hotel.cant_departament.cant_pool }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantPiscinas') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="hotel.hotel.cant_departament.cant_coffee"
              cols="12"
              md="2"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    mdi-coffee
                  </v-icon>
                  <span>
                    {{ hotel.hotel.cant_departament.cant_coffee }}
                  </span>
                </template>
                <span>{{ $t('lbl.cantCafeterias') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="3"
          style="border-left-style: dotted; margin: 15px 0 15px 0;text-align:right"
        >
          <br />
          <h1>
            <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
              {{ Math.ceil(hotel.minPriceMark/hotel.days_search) }} {{ currencyCodeFacturar }}
            </span>
          </h1>
          <span :style="`color: ${$vuetify.theme.themes.light.primary}`">{{ $t('lbl.priceByNight') }}</span>
          <br />
          <b
            v-if="hotel.days_search > 1"
            :style="`color: ${$vuetify.theme.themes.light.primary}`"
          >
            {{ Math.ceil(hotel.minPriceMark) }} {{ currencyCodeFacturar }} {{ $t('lbl.inTotal') }}
          </b>
          <br />
          <br />
          <v-btn
            color="primary"
            class="mb-4"
            :loading="loadingResult"
            @click="send(hotel)"
          >
            <span>{{ $t('btn.select') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <!--<v-row>
          <v-col
            cols="12"
            md="3"
          >
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiCarDoor }}
              </v-icon>
              <b class="ml-5">{{ hotel.hotel.cant_puertas }}</b>
            </fragment><br />
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiAccountOutline }}
              </v-icon>
              <b class="ml-5">{{ hotel.hotel.cant_paxs }}</b>
            </fragment><br />
            <fragment>
              <v-icon color="primary">
                {{ icons.mdiBagSuitcaseOutline }}
              </v-icon>
              <b class="ml-5">{{ hotel.hotel.cant_maletas_small }}</b>
            </fragment>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <fragment v-if="hotel.rentadora.icon === null">
              {{ hotel.rentadora.name }}
            </fragment>
            <fragment v-else>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-img
                    :src="rutaPublic + hotel.rentadora.icon"
                    size="20"
                    height="30"
                    contain
                    aspect-ratio="1"
                  ></v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="mt-3"
                >
                  <v-tooltip
                    v-for="(cara, indCara) in hotel.hotel.caracteristicas"
                    :key="indCara"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="mr-1"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ cara.icon }}
                      </v-icon>
                    </template>
                    <span>{{ cara.name }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </fragment>
          </v-col>
        </v-row>-->
    </v-col>
    <!--<v-col
      cols="12"
      md="3"
      class="text-center"
    >
      <span><b>{{ $tc('lbl.nightsFrom', hotel.days_search) }}</b></span>
      <br />
      <h1>
        <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
          {{ hotel.minPriceMark | currency }}
        </span>
      </h1>
      <br />
      <v-btn
        color="primary"
        class="mb-4 me-3"
        :loading="loadingResult"
        @click="send(hotel)"
      >
        <span>{{ $t('btn.select') }}</span>
      </v-btn>
    </v-col>-->
  </v-row>

  <!--
    <v-container
    v-if="!isLoading"
    fluid
    class="ma-2 rounded-lg"
    style="border: 1px solid"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        md="4"
      >
        <v-img
          :src="resource === 's3' ? rutaS3 + image : rutaPublic + image"
          size="100"
          contain
          height="100%"
          max-width="300px"
          style="text-align: right !important;border-radius: 1rem 0 0 1rem;"
        >
          <template>
            <v-chip
              class="mr-2 mt-2"
              color="primary"
            >
              <span v-if="hotel.tipo_reserva === 'free-sale'">Free Sale</span>
              <span v-else>On Request</span>
            </v-chip>
          </template>
        </v-img>
      </v-col>
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <strong> {{ hotel.hotel.name }} </strong><br />
            <v-row no-gutters>
              <v-col
                cols="12"
                :md="hotel.hotel.categoria.plus ? '4' : '12'"
              >
                <v-rating
                  background-color="default"
                  color="primary"
                  :empty-icon="icons.mdiStarOutline"
                  :full-icon="icons.mdiStar"
                  :half-icon="icons.mdiStarHalfFull"
                  length="5"
                  readonly
                  size="20"
                  dense
                  half-increments
                  :value="hotel.hotel.categoria.cant_estrellas"
                ></v-rating>
              </v-col>
              <v-col
                v-if="hotel.hotel.categoria.plus"
                cols="12"
                md="2"
              >
                PLUS
              </v-col>
            </v-row><br />
            <span>{{ hotel.hotel.operador ? `${$t('lbl.cadena')}: ${hotel.hotel.operador.name}` : '' }}</span><br />
            <span>{{ hotel.proveedor ? `${$t('lbl.proveedor')}: ${hotel.proveedor.name}` : '' }}</span><br />
            <i class="text-caption">{{ `${hotel.hotel.state}, ${hotel.hotel.country}` }}</i><br />
            <i class="text-caption">{{ `${hotel.hotel.tipo_destino.name}` }}</i><br />
            <v-row no-gutters>
              <v-col
                v-if="hotel.hotel.cant_departament.cant_rooms"
                cols="12"
                md="2 "
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-bed-outline
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_rooms }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantRoom') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="hotel.hotel.cant_departament.cant_restaurant"
                cols="12"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-silverware-fork-knife
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_restaurant }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantRest') }}</span>
                </v-tooltip>
              </v-col>

              <v-col
                v-if="hotel.hotel.cant_departament.cant_bars"
                cols="12"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-glass-cocktail
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_bars }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantBars') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="hotel.hotel.cant_departament.cant_pool"
                cols="12"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-pool
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_pool }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantPiscinas') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="hotel.hotel.cant_departament.cant_coffee"
                cols="12"
                md="2"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      mdi-coffee
                    </v-icon>
                    <span>
                      {{ hotel.hotel.cant_departament.cant_coffee }}
                    </span>
                  </template>
                  <span>{{ $t('lbl.cantCafeterias') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="text-center"
      >
        <span><b>{{ $tc('lbl.nightsFrom', hotel.days_search) }}</b></span>
        <br />
        <h1>
          <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
            {{ hotel.minPriceMark | currency }}
          </span>
        </h1>
        <br />
        <v-btn
          color="primary"
          class="mb-4 me-3"
          :loading="loadingResult"
          @click="send(hotel)"
        >
          <span>{{ $t('btn.select') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
  -->
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
  mdiBedEmpty,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indHotel: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      loadingResult: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      image: [],
      resource: 'local',
      services: [],
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiBedEmpty,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      isLoadingPagination: state => state.app.isLoadingPagination,
      currencySymbolFacturar: state => state.app.currencySymbolFacturar,
      currencyCodeFacturar: state => state.app.currencyCodeFacturar,
    }),
  },
  created() {
    this.loadHotel()
    this.getServices()
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'setIndexTarifaEconCotizadorIdaFligth',
      'setCotizadorHotelsResultSelect',
      'updateOcupationHotel',
      'updateOcupationCantByEdadHotel',
      'setCotizadorHotelsRomms',
      'setCotizadorHotelsSelectMarkups',
      'clearCotizadorHotelsResultFilters',
      'setTasaCambioContrate',
      'setTasaCambioFacturar',
    ]),
    loadHotel() {
      if (this.hotel) {
        if (this.hotel.hotel.galery === null) {
          this.image = 'config/hotel_placeholder.jpg'
        } else if (this.hotel.hotel.galery_random === null) {
          this.image = 'config/hotel_placeholder.jpg'
        } else {
          this.image = this.hotel.hotel.galery_random
          this.resource = this.hotel.hotel.resource_random
        }
      } else {
        this.image = 'config/hotel_placeholder.jpg'
      }

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    getServices() {
      this.axios
        .get('nom_servicios_hotels?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.services = res.data.data
          }
        })
    },
    send(hotel) {
      this.loadingResult = true
      this.setCotizadorHotelsResultSelect({
        ...hotel,
        diasReservar: hotel.days_search,
      })

      // GUARDAR LA MONEDA Y EL CODE
      localStorage.setItem('currencyPVP', hotel.currency)
      localStorage.setItem('currencyCodePVP', hotel.currencyCode)

      // GUARDAR EL MARKUPS ORIGIN
      localStorage.setItem('markupsOrigin', JSON.stringify(hotel.markups_origin))

      // ACTUALIZAR LAS OCUPACIONES CON LOS CANT BY EDAD
      // eslint-disable-next-line no-plusplus
      for (let ind = 0; ind < this.cotizadorHotelsResultSelect.ocupation.length; ind++) {
      // for (let ind = 0; ind < this.cotizadorHotels.ocupation.length; ind++) {
        // eslint-disable-next-line no-loop-func
        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          if (element.adultsOcup === this.cotizadorHotelsResultSelect.ocupation[ind].adultsOcup
            && element.childsOcup === this.cotizadorHotelsResultSelect.ocupation[ind].childsOcup
          ) {
            this.updateOcupationCantByEdadHotel({
              ind,
              cantAdulEdad: element.cantAdulEdad,
              cantTeenEdad: element.cantTeenEdad,
              cantMnrEdad: element.cantMnrEdad,
              cantInfEdad: element.cantInfEdad,
            })
          }
        })
      }

      // SUGERIR LAS HABITACIONES
      // eslint-disable-next-line no-plusplus
      for (let pos = 0; pos < this.cotizadorHotelsResultSelect.ocupation.length; pos++) {
        // eslint-disable-next-line no-unused-vars
        const ocupation = this.cotizadorHotelsResultSelect.ocupation[pos]
        let hab = this.cotizadorHotelsResultSelect.rooms[0]
        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          if (element.cantAdulEdad === ocupation.cantAdulEdad
            && element.cantTeenEdad === ocupation.cantTeenEdad
            && element.cantMnrEdad === ocupation.cantMnrEdad
            && element.cantInfEdad === ocupation.cantInfEdad
          ) {
            hab = element
          }
        })

        let mark = {
          markup: 0,
          valueMarkup: 0,
          descuento: 0,
          valueDescuento: 0,
        }

        /* if (hab.days[0].day_by_day[0].adult === 1) {
          if (hab.days[0].day_by_day[0].markups) {
            mark = hab.days[0].day_by_day[0].markups
          }
          this.setCotizadorHotelsSelectMarkups(mark)
        } else if (hab.days[0].day_by_day[0].adult === 2) {
          if (hab.days[0].day_by_day[0].markups) {
            mark = hab.days[0].day_by_day[0].markups
          }
          this.setCotizadorHotelsSelectMarkups(mark)
        } */

        if (hab.days[0].group_net[0].day_by_day[0].markups) {
          mark = hab.days[0].group_net[0].day_by_day[0].markups
        }
        this.setCotizadorHotelsSelectMarkups(mark)

        /* hab.days.forEach(day => {
          day.pricesSeasonDay.forEach(pricesSeasonD => {
            if (pricesSeasonD.pax === 'sgl' && ocupation.adults === 1) {
              priceForRoom += pricesSeasonD.price
              uso = 'sgl'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'dbl' && ocupation.adults === 2) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'dbl'

              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'tpl' && ocupation.adults === 3) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'tpl'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax4' && ocupation.adults === 4) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax4'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax5' && ocupation.adults === 5) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax5'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax6' && ocupation.adults === 6) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax6'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax7' && ocupation.adults === 7) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax7'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            } else if (pricesSeasonD.pax === 'pax8' && ocupation.adults === 8) {
              priceForRoom += pricesSeasonD.price * ocupation.adults
              uso = 'pax8'
              if (pricesSeasonD.markups) {
                mark = pricesSeasonD.markups
              }
              this.setCotizadorHotelsSelectMarkups(mark)
            }
          })
        }) */

        const paxs = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < hab.cantAdulEdad; i++) {
          // for (let i = 0; i < this.cotizadorHotels.ocupation[pos].adults; i++) {
          let principal = false
          if (i === 0) {
            principal = true
          }
          paxs.push({
            principal,
            name: null,
            apellidos: null,
            noDocument: null,
            type: 'ADL',
            edad: null,
          })
        }
        const edadesOcup = hab.edades

        // const edadesOcup = this.cotizadorHotels.ocupation[pos].edades
        // for (let i = 0; i < this.cotizadorHotels.ocupation[pos].childs; i++) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < hab.cantTeenEdad; i++) {
          const eee = edadesOcup[i]
          paxs.push({
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
            type: 'TEEN',
            edad: eee.edad,
          })
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < hab.cantMnrEdad; i++) {
          const eee = edadesOcup[i]
          paxs.push({
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
            type: 'MNR',
            edad: eee.edad,
          })
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < hab.cantInfEdad; i++) {
          const eee = edadesOcup[i]
          paxs.push({
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
            type: 'INF',
            edad: eee.edad,
          })
        }

        let uso = ''
        let priceNeto = 0
        let priceForRoom = 0
        let priceForRoomMark = 0
        const plan = hab.days[0].plan_id

        // PARA BUSCAR EL NETO
        const codeTarifaNet = this.cotizadorHotelsResultSelect.code_tarifa
        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          if (element.cantAdulEdad === ocupation.cantAdulEdad
            && element.cantTeenEdad === ocupation.cantTeenEdad
            && element.cantMnrEdad === ocupation.cantMnrEdad
            && element.cantInfEdad === ocupation.cantInfEdad
          ) {
            if (element.cantAdulEdad === 1) {
              uso = 'sgl'
            } else if (element.cantAdulEdad === 2) {
              uso = 'dbl'
            } else if (element.cantAdulEdad === 3) {
              uso = 'tpl'
            }

            priceForRoom = element.days[0].priceAll
            priceForRoomMark = element.days[0].priceAllMark

            // BUSCAR EL PRECIO NETO X TARIFA/PLAN/HAB
            const planIdNet = element.plan
            const roomIdNet = element.room_id
            element.days.forEach(elementDay => {
              elementDay.group_net.forEach(elementDayGroup => {
                if (codeTarifaNet === elementDayGroup.code_tarifa
                && planIdNet === elementDayGroup.plan_id
                && roomIdNet === elementDayGroup.room_id
                ) {
                  priceNeto += elementDayGroup.price_net
                }
              })
            })
          }
        })

        this.updateOcupationHotel({
          pos,
          paxs,
          room_id: hab.days[0].room_id,
          tipoCama: hab.days[0].tipo_cama,
          tarifasSeason: hab.days[0].tarifasSeason,
          plan,
          codeTarifa: hab.days[0].code_tarifa,
          uso,
          priceNeto,
          priceTotal: priceForRoom,
          priceWithMarkups: priceForRoomMark,
          priceWithDescuento: priceForRoomMark,
          cantAdulEdad: hab.cantAdulEdad,
          cantTeenEdad: hab.cantTeenEdad,
          cantMnrEdad: hab.cantMnrEdad,
          cantInfEdad: hab.cantInfEdad,
        })
      }

      // GUARDAR EL CAMBIO DE LA MONEDA DEL CONTRATO
      const contrateCurrencyCode = hotel.contrate_currency_code
      if (localStorage.getItem('exchange_currency') !== null) {
        const exc = JSON.parse(localStorage.getItem('exchange_currency'))
        exc.exchanges.forEach(element => {
          if (element.currency_code === contrateCurrencyCode) {
            this.setTasaCambioContrate(element.value)
          }

          if (element.currency_code === this.currencyCodeFacturar) {
            this.setTasaCambioFacturar(element.value)
          }
        })
      }

      this.setCotizadorHotelsRomms()

      // LIMPIAR LOS FILTROS
      // this.clearCotizadorHotelsResultFilters()
      this.sendReply(hotel)
    },
    sendReply(hotel) {
      this.setCotizadorHotelsResultSelect({
        ...hotel,
        diasReservar: hotel.days_search,
      })

      // GUARDAR LA MONEDA Y EL CODE
      localStorage.setItem('currencyPVP', hotel.currency)
      localStorage.setItem('currencyCodePVP', hotel.currencyCode)

      // ACTUALIZAR LAS OCUPACIONES CON LOS CANT BY EDAD
      // eslint-disable-next-line no-plusplus
      for (let ind = 0; ind < this.cotizadorHotelsResultSelect.ocupation.length; ind++) {
        // eslint-disable-next-line no-loop-func
        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          if (element.adultsOcup === this.cotizadorHotelsResultSelect.ocupation[ind].adultsOcup
            && element.childsOcup === this.cotizadorHotelsResultSelect.ocupation[ind].childsOcup
          ) {
            this.updateOcupationCantByEdadHotel({
              ind,
              cantAdulEdad: element.cantAdulEdad,
              cantTeenEdad: element.cantTeenEdad,
              cantMnrEdad: element.cantMnrEdad,
              cantInfEdad: element.cantInfEdad,
            })
          }
        })
      }

      // SUGERIR LAS HABITACIONES
      // eslint-disable-next-line no-plusplus
      for (let pos = 0; pos < this.cotizadorHotelsResultSelect.ocupation.length; pos++) {
      // eslint-disable-next-line no-unused-vars
        const ocupation = this.cotizadorHotelsResultSelect.ocupation[pos]
        let hab = this.cotizadorHotelsResultSelect.rooms[0]
        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          if (element.cantAdulEdad === ocupation.cantAdulEdad
          && element.cantTeenEdad === ocupation.cantTeenEdad
          && element.cantMnrEdad === ocupation.cantMnrEdad
          && element.cantInfEdad === ocupation.cantInfEdad
          ) {
            hab = element
          }
        })

        let mark = {
          markup: 0,
          valueMarkup: 0,
          descuento: 0,
          valueDescuento: 0,
        }

        if (hab.days[0].group_net[0].day_by_day[0].markups) {
          mark = hab.days[0].group_net[0].day_by_day[0].markups
        }
        this.setCotizadorHotelsSelectMarkups(mark)

        const paxs = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < hab.cantAdulEdad; i++) {
          // for (let i = 0; i < this.cotizadorHotels.ocupation[pos].adults; i++) {
          let principal = false
          if (i === 0) {
            principal = true
          }
          paxs.push({
            principal,
            name: null,
            apellidos: null,
            noDocument: null,
            type: 'ADL',
            edad: null,
          })
        }
        const edadesOcup = hab.edades

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < hab.cantTeenEdad; i++) {
          const eee = edadesOcup[i]
          paxs.push({
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
            type: 'TEEN',
            edad: eee.edad,
          })
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < hab.cantMnrEdad; i++) {
          const eee = edadesOcup[i]
          paxs.push({
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
            type: 'MNR',
            edad: eee.edad,
          })
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < hab.cantInfEdad; i++) {
          const eee = edadesOcup[i]
          paxs.push({
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
            type: 'INF',
            edad: eee.edad,
          })
        }

        let uso = ''
        let priceForRoom = 0
        let priceNeto = 0
        let priceForRoomMark = 0
        const plan = hab.days[0].plan_id

        // PARA BUSCAR EL NETO
        const codeTarifaNet = this.cotizadorHotelsResultSelect.code_tarifa
        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          if (element.cantAdulEdad === ocupation.cantAdulEdad
          && element.cantTeenEdad === ocupation.cantTeenEdad
          && element.cantMnrEdad === ocupation.cantMnrEdad
          && element.cantInfEdad === ocupation.cantInfEdad
          ) {
            if (element.cantAdulEdad === 1) {
              uso = 'sgl'
            } else if (element.cantAdulEdad === 2) {
              uso = 'dbl'
            } else if (element.cantAdulEdad === 3) {
              uso = 'tpl'
            }

            priceForRoom = element.days[0].priceAll
            priceForRoomMark = element.days[0].priceAllMark

            // BUSCAR EL PRECIO NETO X TARIFA/PLAN/HAB
            const planIdNet = element.plan
            const roomIdNet = element.room_id
            element.days.forEach(elementDay => {
              elementDay.group_net.forEach(elementDayGroup => {
                if (codeTarifaNet === elementDayGroup.code_tarifa
                && planIdNet === elementDayGroup.plan_id
                && roomIdNet === elementDayGroup.room_id
                ) {
                  priceNeto += elementDayGroup.price_net
                }
              })
            })
          }
        })

        this.updateOcupationHotel({
          pos,
          paxs,
          room_id: hab.days[0].room_id,
          tipoCama: hab.days[0].tipo_cama,
          tarifasSeason: hab.days[0].tarifasSeason,
          plan,
          codeTarifa: hab.days[0].code_tarifa,
          uso,
          priceNeto,
          priceTotal: priceForRoom,
          priceWithMarkups: priceForRoomMark,
          priceWithDescuento: priceForRoomMark,
          cantAdulEdad: hab.cantAdulEdad,
          cantTeenEdad: hab.cantTeenEdad,
          cantMnrEdad: hab.cantMnrEdad,
          cantInfEdad: hab.cantInfEdad,
        })

        this.setCotizadorHotelsRomms()
      }

      this.$router.push({ name: 'selected-cotizador-hotels' })
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>
