<template>
  <fragment>
    <!--<v-row class="my-2">
      <v-col cols="12">
        <v-card>
          <v-container grid-list-xs>
            <div class="d-flex justify-start">
              <v-icon :color="cotizadorFlightFavorite.length > 0 ? 'primary' : '' ">
                {{ icons.mdiHeart }}
              </v-icon>
              <p class="mb-0 ml-1">
                {{ cotizadorFlightFavorite.length }}
              </p>
              <v-divider
                vertical
                class="mx-3"
              ></v-divider>
              <p class="mb-0 ml-1">
                <span
                  v-if="cotizadorFlightFavorite.length > 0"
                  style="cursor: pointer;"
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                  @click="getFavorite"
                >{{ $t('lbl.prepararSelect') }}</span>
                <span v-else>{{ $t('lbl.prepararSelect') }}</span>
              </p>
              <fragment>
                <v-spacer></v-spacer>
                <v-divider
                  vertical
                  class="mx-3"
                ></v-divider>
                <v-icon :color="cotizadorFlightFavorite.length > 0 ? 'primary' : '' ">
                  {{ icons.mdiHeartBroken }}
                </v-icon>
                <p class="mb-0 ml-1">
                  <span
                    style="cursor: pointer"
                    @click="clearFavorites"
                  >{{ $t('lbl.borrarSelect') }}</span>
                </p>
              </fragment>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>-->
    <v-row v-if="cotizadorCarsResult.length > 0">
      <v-col
        v-for="(result, indCar) in cotizadorCarsResult"
        :key="indCar"
        cols="12"
      >
        <CarResult
          :car="result"
          :ind-car="indCar"
        />
      </v-col>
    </v-row>
    <NoResultsCars v-else />
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
  mdiHeart,
  mdiHeartBroken,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import CarResult from './utils/CarResult.vue'
import NoResultsCars from '../../utils/NoResultsCars.vue'

export default {
  components: {
    NoResultsCars,
    CarResult,
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
        mdiHeart,
        mdiHeartBroken,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
      avalibleFiltrosCotizadorCar: state => state.app.avalibleFiltrosCotizadorCar,
    }),
  },
  created() {
    // this.getDisponibilidadFlightIda()
  },
  methods: {
    ...mapMutations(['setTarifaEconCotizadorIdaFligth', 'clearCotizadorFlightFavorite', 'clearIdFlightFavorite']),
    getDisponibilidadFlightIda() {
      if (this.aerolineCotizadorIdaFlight.length === 0) {
        this.$router.push({ name: 'cotizador' })
      }
      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    clearFavorites() {
      this.clearCotizadorFlightFavorite()
      this.clearIdFlightFavorite()
    },
    getFavorite() {
      this.$router.push({ name: 'cotizador-favorite' })
    },
  },
}
</script>
