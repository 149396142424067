<template>
  <fragment>
    <!--<v-row class="my-2">
      <v-col cols="12">
        <v-card>
          <v-container grid-list-xs>
            <div class="d-flex justify-start">
              <v-icon :color="cotizadorFlightFavorite.length > 0 ? 'primary' : '' ">
                {{ icons.mdiHeart }}
              </v-icon>
              <p class="mb-0 ml-1">
                {{ cotizadorFlightFavorite.length }}
              </p>
              <v-divider
                vertical
                class="mx-3"
              ></v-divider>
              <p class="mb-0 ml-1">
                <span
                  v-if="cotizadorFlightFavorite.length > 0"
                  style="cursor: pointer;"
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                  @click="getFavorite"
                >{{ $t('lbl.prepararSelect') }}</span>
                <span v-else>{{ $t('lbl.prepararSelect') }}</span>
              </p>
              <fragment>
                <v-spacer></v-spacer>
                <v-divider
                  vertical
                  class="mx-3"
                ></v-divider>
                <v-icon :color="cotizadorFlightFavorite.length > 0 ? 'primary' : '' ">
                  {{ icons.mdiHeartBroken }}
                </v-icon>
                <p class="mb-0 ml-1">
                  <span
                    style="cursor: pointer"
                    @click="clearFavorites"
                  >{{ $t('lbl.borrarSelect') }}</span>
                </p>
              </fragment>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>-->
    <v-row v-if="cotizadorHotelsResultOrigin.length > 0">
      <template v-if="!isLoadingSearch">
        <template>
          <v-col
            v-for="(result, indHotel) in cotizadorHotelsResultPaginate"
            :key="indHotel"
            cols="12"
          >
            <HotelResult
              :hotel="result"
              :ind-hotel="indHotel"
            />
          </v-col>
        </template>
        <v-col
          cols="12"
          class="pt-2"
        >
          <v-row>
            <v-col
              lg="12"
              cols="12"
              class="d-flex justify-end"
            >
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                total-visible="5"
                @input="onPageChange"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
    <NoResultsHotels v-else />
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
  mdiHeart,
  mdiHeartBroken,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import HotelResult from './utils/HotelResult.vue'
import NoResultsHotels from '../../utils/NoResultsHotels.vue'

export default {
  components: {
    NoResultsHotels,
    HotelResult,
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
        mdiHeart,
        mdiHeartBroken,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultOrigin: state => state.app.cotizadorHotelsResultOrigin,
      cotizadorHotelsResultPaginate: state => state.app.cotizadorHotelsResultPaginate,
      isLoadingSearch: state => state.app.isLoadingSearch,
      isLoadingPagination: state => state.app.isLoadingPagination,
      pagination: state => state.app.pagination,
      itemsPerPage: state => state.app.itemsPerPage,
    }),
  },
  created() {
    // this.getDisponibilidadFlightIda()
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'clearCotizadorFlightFavorite',
      'clearIdFlightFavorite',
      'updateIsLoadingSearch',
      'setCotizadorHotelsResultPaginate',
      'setIsLoadingPagination',
    ]),
    onPageChange() {
      this.updateIsLoadingSearch(true)
      this.setCotizadorHotelsResultPaginate([])
      const arr = []
      const first = (this.pagination.current - 1) * this.itemsPerPage
      let last = this.pagination.current * this.itemsPerPage
      if (this.pagination.current === this.pagination.total) {
        last = this.cotizadorHotelsResult.length
      }
      // eslint-disable-next-line no-plusplus
      for (let index = first; index < last; index++) {
        arr.push(this.cotizadorHotelsResult[index])
      }
      this.setCotizadorHotelsResultPaginate(arr)

      setTimeout(() => {
        this.updateIsLoadingSearch(false)
      }, 100)
    },
    getDisponibilidadFlightIda() {
      if (this.aerolineCotizadorIdaFlight.length === 0) {
        this.$router.push({ name: 'cotizador' })
      }
      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    clearFavorites() {
      this.clearCotizadorFlightFavorite()
      this.clearIdFlightFavorite()
    },
    getFavorite() {
      this.$router.push({ name: 'cotizador-favorite' })
    },
  },
}
</script>
