<template>
  <v-row v-if="!isLoading">
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <span class="ma-2">{{ `${$t('menu.booking')} / ${$t('menu.cotizador')}` }}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="(user.nivel === -1 || user.nivel === 0) && posCotizador === 0"
            class="ma-2"
            outlined
            color="primary"
            @click="$router.push({ name: 'schedule' })"
          >
            <v-icon left>
              {{ icons.mdiCalendarMultiselect }}
            </v-icon>
            {{ $t('btn.programation') }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <Cotizador v-if="!offline" />
          <v-row v-if="offline">
            <v-col
              cols="12"
              md="12"
              class="d-flex justify-center"
            >
              <v-alert
                class="mt-2"
                outlined
                type="info"
              >
                {{ $t('lbl.userOffline') }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { mdiCalendarMultiselect } from '@mdi/js'
import Cotizador from './form/Cotizador.vue'

export default {
  components: {
    Cotizador,
  },
  data() {
    return {
      icons: {
        mdiCalendarMultiselect,
      },
      isLoading: true,
      user: {},
      offline: false,
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.profile()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else {
            this.user = response.data.data
            if (this.user.company_id) {
              if (this.user.company) {
                if (this.user.company.offline) {
                  this.offline = this.user.company.offline
                }
              }
            }
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
