var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('fragment',[_c('v-row',{staticClass:"py-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"12","md":!_vm.cotizadorCars.entregaDiffRecogida ? '6' : '3'}},[_c('div',{staticClass:"d-flex justify-start align-center"},[(_vm.cotizadorCars.recogida === null || _vm.cotizadorCars.recogida === '')?_c('v-autocomplete',{staticClass:"pr-2",style:(!_vm.cotizadorCars.entregaDiffRecogida ? '' : 'border-radius: 5px 0px 0px 5px'),attrs:{"items":_vm.itemsRecogida,"search-input":_vm.searchRecogida,"label":!_vm.cotizadorCars.entregaDiffRecogida ? _vm.$t('lbl.recogidaEntrega') : _vm.$t('lbl.recogida'),"outlined":"","dense":"","item-text":"nameComodin","item-value":"nameComodin","single-line":"","clearable":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchRecogida=$event},"update:search-input":function($event){_vm.searchRecogida=$event},"change":_vm.changeRecogida},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultRecogida ? _vm.$t('lbl.noResultSearchFlight') : _vm.$t('lbl.recogida'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nameComodin))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,1910356362),model:{value:(_vm.cotizadorCars.recogida),callback:function ($$v) {_vm.$set(_vm.cotizadorCars, "recogida", $$v)},expression:"cotizadorCars.recogida"}}):_c('v-text-field',{ref:"autocomplete",style:(!_vm.cotizadorCars.entregaDiffRecogida ? '' : 'border-radius: 5px 0px 0px 5px'),attrs:{"label":!_vm.cotizadorCars.entregaDiffRecogida ? _vm.$t('lbl.recogidaEntrega') : _vm.$t('lbl.recogida'),"outlined":"","dense":"","single-line":"","clearable":""},on:{"click:clear":_vm.clearRecogida,"input":_vm.inputRecogida},model:{value:(_vm.cotizadorCars.recogida.name),callback:function ($$v) {_vm.$set(_vm.cotizadorCars.recogida, "name", $$v)},expression:"cotizadorCars.recogida.name"}})],1)]),(_vm.cotizadorCars.entregaDiffRecogida)?_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"3"}},[(_vm.cotizadorCars.entrega === null || _vm.cotizadorCars.entrega === '')?_c('v-autocomplete',{staticClass:"pr-2",staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"items":_vm.itemsEntrega,"search-input":_vm.searchEntrega,"label":_vm.$t('lbl.entrega'),"outlined":"","dense":"","item-text":"nameComodin","item-value":"nameComodin","return-object":"","single-line":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchEntrega=$event},"update:search-input":function($event){_vm.searchEntrega=$event},"change":_vm.changeEntrega},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultEntrega ? _vm.$t('lbl.noResultSearchFlight') : _vm.$t('lbl.entrega'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nameComodin))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.name.slice(0, 20)) + "...")))])]}}],null,false,3882262844),model:{value:(_vm.cotizadorCars.entrega),callback:function ($$v) {_vm.$set(_vm.cotizadorCars, "entrega", $$v)},expression:"cotizadorCars.entrega"}}):_c('v-text-field',{ref:"autocomplete",staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"label":_vm.$t('lbl.entrega'),"outlined":"","dense":"","single-line":"","clearable":""},on:{"click:clear":_vm.clearEntrega,"input":_vm.inputEntrega},model:{value:(_vm.cotizadorCars.entrega.name),callback:function ($$v) {_vm.$set(_vm.cotizadorCars.entrega, "name", $$v)},expression:"cotizadorCars.entrega.name"}})],1):_vm._e(),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"3"}},[_c('vc-date-picker',{staticClass:"d-flex justify-center;z-index: 1 !important",attrs:{"outlined":"","dense":"","hide-details":"","min-date":_vm.$moment(new Date())
            .add(_vm.minRelease, 'days')
            .toDate(),"mode":"dateTime","model-config":_vm.modelConfig},on:{"input":_vm.changeRecogida},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var inputValue = ref.inputValue;
            var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"label":_vm.$t('lbl.dateRecogida'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}],null,false,4282073590),model:{value:(_vm.cotizadorCars.dateRecogida),callback:function ($$v) {_vm.$set(_vm.cotizadorCars, "dateRecogida", $$v)},expression:"cotizadorCars.dateRecogida"}}),(!_vm.no_include_hour_recogida)?_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.verifyHorarios'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-information ")])],1)]}}],null,false,902456288)},[_c('span',[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(((_vm.$t('lbl.horariosDe')) + " " + (_vm.cotizadorCars.recogida ? _vm.cotizadorCars.recogida.name : '')))+" ")]),_c('br'),_c('br'),_vm._l((_vm.hours_recogida),function(hours_recog,indRec){return _c('span',{key:indRec},[_c('v-slide-group',{staticClass:"mb-2",attrs:{"multiple":""},model:{value:(hours_recog.modelSemana),callback:function ($$v) {_vm.$set(hours_recog, "modelSemana", $$v)},expression:"hours_recog.modelSemana"}},_vm._l((_vm.semanas),function(item,index){return _c('v-slide-item',{key:index,staticClass:"px-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":"","x-small":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true)})}),1),_vm._l((hours_recog.horarios),function(horar,indH){return _c('span',{key:indH,staticClass:"mt-2"},[_vm._v(" "+_vm._s(horar.from)+" - "+_vm._s(horar.to)),_c('br')])}),_c('br')],2)})],2)])],1):_vm._e()],1),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"3"}},[_c('vc-date-picker',{attrs:{"outlined":"","dense":"","hide-details":"","min-date":_vm.$moment(_vm.cotizadorCars.dateRecogida)
            .add(1, 'days')
            .toDate(),"mode":"dateTime","model-config":_vm.modelConfig},on:{"input":_vm.changeEntrega},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var inputValue = ref.inputValue;
            var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({ref:"dateTo",staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"disabled":!_vm.cotizadorCars.dateRecogida,"label":_vm.$t('lbl.dateEntrega'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}],null,false,2717097565),model:{value:(_vm.cotizadorCars.dateEntrega),callback:function ($$v) {_vm.$set(_vm.cotizadorCars, "dateEntrega", $$v)},expression:"cotizadorCars.dateEntrega"}}),(!_vm.no_include_hour_entrega)?_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.verifyHorarios'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-information ")])],1)]}}],null,false,902456288)},[_c('span',[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(((_vm.$t('lbl.horariosDe')) + " " + (_vm.cotizadorCars.entregaDiffRecogida ? _vm.cotizadorCars.entrega ? _vm.cotizadorCars.entrega.name : '' : _vm.cotizadorCars.recogida ? _vm.cotizadorCars.recogida.name : '')))+" ")]),_c('br'),_c('br'),_vm._l((_vm.hours_entrega),function(hours_entr,indEnt){return _c('span',{key:indEnt},[_c('v-slide-group',{staticClass:"mb-2",attrs:{"multiple":""},model:{value:(hours_entr.modelSemana),callback:function ($$v) {_vm.$set(hours_entr, "modelSemana", $$v)},expression:"hours_entr.modelSemana"}},_vm._l((_vm.semanas),function(item,index){return _c('v-slide-item',{key:index,staticClass:"px-1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"input-value":active,"active-class":"primary white--text","depressed":"","rounded":"","x-small":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true)})}),1),_vm._l((hours_entr.horarios),function(horar,indH){return _c('span',{key:indH,staticClass:"mt-2"},[_vm._v(" "+_vm._s(horar.from)+" - "+_vm._s(horar.to)),_c('br')])}),_c('br')],2)})],2)])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-switch',{staticClass:"pb-0 mt-0",attrs:{"label":_vm.$t('lbl.entregaDiffRecogida')},on:{"change":_vm.reseatDiffRecogida},model:{value:(_vm.cotizadorCars.entregaDiffRecogida),callback:function ($$v) {_vm.$set(_vm.cotizadorCars, "entregaDiffRecogida", $$v)},expression:"cotizadorCars.entregaDiffRecogida"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.transmisiones,"item-text":"name","item-value":"id","label":_vm.$t('menu.trasnmision'),"outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.cotizadorCars.transmision_id),callback:function ($$v) {_vm.$set(_vm.cotizadorCars, "transmision_id", $$v)},expression:"cotizadorCars.transmision_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{staticClass:"pl-2",attrs:{"items":_vm.itemsRentadora,"search-input":_vm.searchRentadora,"label":_vm.$t('menu.rentadora'),"outlined":"","dense":"","item-text":"name","item-value":"id","return-object":"","single-line":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchRentadora=$event},"update:search-input":function($event){_vm.searchRentadora=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultRentadora ? _vm.$t('lbl.noResultSearchFlight') : _vm.$t('menu.rentadora'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.name.slice(0, 20)) + "...")))])]}}],null,false,3118562601),model:{value:(_vm.cotizadorCars.rentadora_id),callback:function ($$v) {_vm.$set(_vm.cotizadorCars, "rentadora_id", $$v)},expression:"cotizadorCars.rentadora_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":!_vm.cotizadorCars.recogida &&
              !_vm.cotizadorCars.entrega &&
              !_vm.cotizadorCars.entregaDiffRecogida &&
              !_vm.cotizadorCars.dateRecogida &&
              !_vm.cotizadorCars.dateEntrega &&
              !_vm.cotizadorCars.transmision_id &&
              !_vm.cotizadorCars.rentadora_id
              ? '12'
              : '6'}},[_c('v-btn',{attrs:{"color":"primary","width":"100%","loading":_vm.loadingSearch},on:{"click":_vm.search}},[_vm._v(" "+_vm._s(_vm.$t('btn.search'))+" ")])],1),(
            _vm.cotizadorCars.recogida ||
              _vm.cotizadorCars.entrega ||
              _vm.cotizadorCars.entregaDiffRecogida ||
              _vm.cotizadorCars.dateRecogida ||
              _vm.cotizadorCars.dateEntrega ||
              _vm.cotizadorCars.transmision_id ||
              _vm.cotizadorCars.rentadora_id
          )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"error","width":"100%"},on:{"click":_vm.clearForm}},[_vm._v(" "+_vm._s(_vm.$t('btn.clear'))+" ")])],1):_vm._e()],1)],1)],1),(!_vm.loadingSearch && (_vm.cotizadorCarsResult.length > 0 || _vm.avalibleFiltrosCotizadorCar))?_c('DisponibilidadCars'):(_vm.searchingCar)?_c('NoResultsCars'):_c('div',{staticStyle:{"min-height":"250px"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }