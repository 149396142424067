<template>
  <v-card
    class="my-5 mx-auto"
    min-width="300"
    max-width="300"
    round
    style="border-radius: 1rem 1rem 1rem 1rem;"
  >
    <v-img
      :src="resource === 's3' ? rutaS3 + image : rutaPublic + image"
      size="100"
      contain
      height="100%"
      max-width="300px"
      style="text-align: right !important;border-radius: 1rem 1rem 0 0;cursor: pointer;"
      @click="send(item)"
    >
      <template>
        <v-chip
          class="mr-2 mt-2"
          color="primary"
        >
          <span v-if="item.tipo_reserva === 'free-sale'">Free Sale</span>
          <span v-else>On Request</span>
        </v-chip>
      </template>
    </v-img>
    <v-row dense>
      <v-card-text>
        <v-row no-gutters>
          <v-col
            cols="12"
            class="text-right mt-2"
          >
            <v-btn
              icon
              color="default"
              @click="$emit('cerrarCard')"
            >
              <v-icon
                color="default"
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            style="cursor: pointer;"
            @click="send(item)"
          >
            <h2 color="primary">
              <v-tooltip
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.proveedor.name | truncate(18) }}
                  </span>
                </template>
                <span>{{ item.proveedor.name }}</span>
              </v-tooltip>
            </h2>
            <h3 color="primary">
              {{ item.hotel.name }}
            </h3>
            <v-row no-gutters>
              <v-col
                cols="12"
                :md="item.hotel.categoria.plus ? '4' : '12'"
              >
                <v-rating
                  background-color="default"
                  color="primary"
                  :empty-icon="icons.mdiStarOutline"
                  :full-icon="icons.mdiStar"
                  :half-icon="icons.mdiStarHalfFull"
                  length="5"
                  readonly
                  size="20"
                  dense
                  half-increments
                  :value="item.hotel.categoria.cant_estrellas"
                ></v-rating>
              </v-col>
              <v-col
                v-if="item.hotel.categoria.plus"
                cols="12"
                md="2"
              >
                PLUS
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="text-right mt-2"
            style="cursor: pointer;"
            @click="send(item)"
          >
            <h2
              :style="`color: ${$vuetify.theme.themes.light.primary}`"
            >
              {{ item.priceDayC }} {{ item.currency }}
              <span
                class="text-caption"
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >/ {{ $t('lbl.night') }}</span>
            </h2>
            <div color="primary">
              <b>{{ item.priceC }} {{ item.currency }} {{ $t('lbl.inTotal') }}</b>
            </div>
          </v-col>
          <!--<v-col cols="1">
            <v-btn
              icon
              @click="
                showCard = !showCard
              "
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>-->
        </v-row>
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
  mdiBedEmpty,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    item: {
      type: Object,
    },
  },
  data() {
    return {
      primaryColor: this.$vuetify.theme.themes.light.primary,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      rutaS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      image: [],
      resource: 'local',
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiBedEmpty,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      cotizadorHotelsResultPaginate: state => state.app.cotizadorHotelsResultPaginate,
    }),
  },
  created() {
    this.loadHotel()
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'setIndexTarifaEconCotizadorIdaFligth',
      'setCotizadorHotelsResultSelect',
      'updateOcupationHotel',
      'setCotizadorHotelsRomms',
      'setCotizadorHotelsSelectMarkups',
      'clearCotizadorHotelsResultFilters',
    ]),
    loadHotel() {
      if (this.item.hotel.galery === null) {
        this.image = 'config/hotel_placeholder.jpg'
      } else if (this.item.hotel.galery_random === null) {
        this.image = 'config/hotel_placeholder.jpg'
      } else {
        this.image = this.item.hotel.galery_random
        this.resource = this.item.hotel.resource_random
      }

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    send(hotel) {
      this.setCotizadorHotelsResultSelect({
        ...hotel,
        diasReservar: hotel.days_search,
      })

      // SUGERIR LAS HABITACIONES
      // eslint-disable-next-line no-plusplus
      for (let pos = 0; pos < this.cotizadorHotels.ocupation.length; pos++) {
        // eslint-disable-next-line no-unused-vars
        const ocupation = this.cotizadorHotels.ocupation[pos]
        let hab = this.cotizadorHotelsResultSelect.rooms[0]
        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          if (element.adultsOcup === ocupation.adults && element.childsOcup === ocupation.childs) {
            hab = element
          }
        })

        let mark = {
          markup: 0,
          valueMarkup: 0,
          descuento: 0,
          valueDescuento: 0,
        }

        if (hab.days[0].day_by_day[0].adult === 1) {
          if (hab.days[0].day_by_day[0].markups) {
            mark = hab.days[0].day_by_day[0].markups
          }
          this.setCotizadorHotelsSelectMarkups(mark)
        } else if (hab.days[0].day_by_day[0].adult === 2) {
          if (hab.days[0].day_by_day[0].markups) {
            mark = hab.days[0].day_by_day[0].markups
          }
          this.setCotizadorHotelsSelectMarkups(mark)
        }

        const paxs = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.cotizadorHotels.ocupation[pos].adults; i++) {
          let principal = false
          if (i === 0) {
            principal = true
          }
          paxs.push({
            principal,
            name: null,
            apellidos: null,
            noDocument: null,
          })
        }
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.cotizadorHotels.ocupation[pos].childs; i++) {
          paxs.push({
            principal: false,
            name: null,
            apellidos: null,
            noDocument: null,
          })
        }

        let uso = ''
        let priceNeto = 0
        let priceForRoom = 0
        let priceForRoomMark = 0
        const plan = hab.days[0].plan_id

        // PARA BUSCAR EL NETO
        const codeTarifaNet = this.cotizadorHotelsResultSelect.code_tarifa
        this.cotizadorHotelsResultSelect.rooms.forEach(element => {
          if (element.adultsOcup === ocupation.adults && element.childsOcup === ocupation.childs) {
            if (element.adultsOcup === 1) {
              uso = 'sgl'
            } else if (element.adultsOcup === 2) {
              uso = 'dbl'
            } else if (element.adultsOcup === 3) {
              uso = 'tpl'
            }

            priceForRoom = element.days[0].priceAll
            priceForRoomMark = element.days[0].priceAllMark

            // BUSCAR EL PRECIO NETO X TARIFA/PLAN/HAB
            const planIdNet = element.plan
            const roomIdNet = element.room_id
            element.days.forEach(elementDay => {
              elementDay.group_net.forEach(elementDayGroup => {
                if (codeTarifaNet === elementDayGroup.code_tarifa
                && planIdNet === elementDayGroup.plan_id
                && roomIdNet === elementDayGroup.room_id
                ) {
                  priceNeto += elementDayGroup.price_net
                }
              })
            })
          }
        })
        this.updateOcupationHotel({
          pos,
          paxs,
          room_id: parseInt(hab.days[0].room_id, 10),
          plan,
          uso,
          priceNeto,
          priceTotal: priceForRoom,
          priceWithMarkups: priceForRoomMark,
          priceWithDescuento: priceForRoomMark,
          cantAdulEdad: hab.cantAdulEdad,
          cantTeenEdad: hab.cantTeenEdad,
          cantMnrEdad: hab.cantMnrEdad,
          cantInfEdad: hab.cantInfEdad,
        })
        this.setCotizadorHotelsRomms()

        // LIMPIAR LOS FILTROS
        this.clearCotizadorHotelsResultFilters()
      }

      this.$router.push({ name: 'selected-cotizador-hotels' })
    },
  },
}
</script>
<style lang="scss">
@import "~leaflet/dist/leaflet.css";

/*.mini-map {
        width:100%;
        height:250px !important;
    }*/

.leaflet-popup-content {
    max-width: auto;
    width: auto !important;
    height: 200px;
    //overflow-y: scroll;
}

.markerIcon {
    font-size: 14px;
    border: 1px solid var(--v-primary-base);
    background: #fff;
    border-radius: 6px;
    text-align: center;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
}
.markerIcon__price {
    color: var(--v-primary-base);
    margin: 10px;
    white-space: nowrap;
    line-height: 18px;
}
.markerIcon:focus .markerIcon__price,
.markerIcon__price:hover {
    color: #fff;
}

.markerIcon:after,
.markerIcon:before {
    content: "";
    position: absolute;
    top: 97%;
    left: 50%;
    border-style: solid;
}
.markerIcon:before {
    margin-left: -7px;
    border-width: 7px;
    border-color: #fff transparent transparent;
    z-index: 1;
}
.markerIcon:after {
    margin-left: -8px;
    border-width: 8px;
    border-color: var(--v-primary-base) transparent transparent;
}
.markerIcon:focus,
.markerIcon:hover {
    border: 1px solid var(--v-primary-base);
    background: var(--v-primary-base);
}
.markerIcon:focus .markerIcon__price,
.markerIcon:hover .markerIcon__price {
    color: #fff;
}
.markerIcon:focus:before,
.markerIcon:hover:before {
    content: " ";
    border-color: var(--v-primary-base) transparent transparent;
}
.markerIcon:focus:after,
.markerIcon:hover:after {
    content: " ";
    border-color: #fff transparent transparent;
}
</style>
