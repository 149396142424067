var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('v-container',{staticClass:"ma-2 rounded-lg",staticStyle:{"border":"1px solid"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-img',{attrs:{"src":_vm.nameB2B === 'ttttt'
            ? _vm.rutaS3 + _vm.remplaceUrl(_vm.image)
            : _vm.nameB2B === 'wwwww'
              ? _vm.rutaS3 + _vm.image
              : _vm.rutaPublic + _vm.image,"size":"100","height":"125"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.car.similares.length === 0)?_c('strong',[_vm._v(" "+_vm._s(_vm.car.car.name)+" "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary"}},[(_vm.car.onRequest)?_c('span',[_vm._v("On Request")]):_c('span',[_vm._v("Free Sale")])])],1):_c('fragment',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_c('strong',[_vm._v(" "+_vm._s(((_vm.car.car.name) + " " + (_vm.$t('lbl.orSimilar'))))+" "),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary"}},[(_vm.car.onRequest)?_c('span',[_vm._v("On Request")]):_c('span',[_vm._v("Free Sale")])])],1)])]}}],null,false,795748636)},[_c('span',_vm._l((_vm.car.similares),function(simi,indSimi){return _c('span',{key:indSimi},[_vm._v(" "+_vm._s(simi.name)),_c('br')])}),0)])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('fragment',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCarDoor)+" ")]),_c('b',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.car.car.cant_puertas))])],1),_c('br'),_c('fragment',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")]),_c('b',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.car.car.cant_paxs))])],1),_c('br'),_c('fragment',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiBagSuitcaseOutline)+" ")]),_c('b',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.car.car.cant_maletas_small))])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[(_vm.car.rentadora.icon === null)?_c('fragment',[_vm._v(" "+_vm._s(_vm.car.rentadora.name)+" ")]):_c('fragment',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-img',{attrs:{"src":_vm.rutaPublic + _vm.car.rentadora.icon,"size":"20","height":"30","contain":"","aspect-ratio":"1"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"12"}},_vm._l((_vm.car.car.caracteristicas),function(cara,indCara){return _c('fragment',{key:indCara,attrs:{"top":""}},[(cara)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(cara.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(cara.name))])]):_vm._e()],1)}),1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4"}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.totalDesde')))])]),_c('br'),_c('h1',[_c('span',{style:(("color: " + (_vm.$vuetify.theme.themes.light.primary)))},[_vm._v(_vm._s(_vm._f("currency")(_vm.car.tarifaAllDayMark,_vm.currencySymbolFacturar)))])]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.byDayDesde')))])]),_c('br'),_c('span',{style:(("color: " + (_vm.$vuetify.theme.themes.light.primary)))},[_vm._v(_vm._s(_vm._f("currency")(_vm.car.tarifaDayMark,_vm.currencySymbolFacturar)))]),_c('br'),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loadingResult},on:{"click":function($event){return _vm.send(_vm.car)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.select')))])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }